<template>
    <div class="breakdowns-widget">
        <div class="col-12 d-flex align-items-center justify-content-end" v-if="!showTable && showChart">
            <b-form-select id="breakdowns-type-filter" class="mb-2 mr-sm-2 mb-sm-0" v-model="type" :options="filterOptions.type" @change="requests"></b-form-select>
        </div>
        <div class="col-12 d-flex align-items-center justify-content-end" v-else-if="!latest && !showTable && showChart">
            <b-button variant="light" class="mr-auto" @click="onExport" >Export</b-button>
            <b-form inline @submit.prevent>
                <label class="mr-sm-2" for="breakdowns-type-filter">Type:</label>
                <b-form-select id="breakdowns-type-filter" class="mb-2 mr-sm-2 mb-sm-0" v-model="type" :options="filterOptions.type" @change="requests"></b-form-select>
                <label class="mr-sm-2" for="breakdowns-notation-filter">Notation:</label>
                <b-form-select id="breakdowns-notation-filter" class="mb-2 mr-sm-2 mb-sm-0" v-model="notation" :options="filterOptions.notation" @change="onNotationChange"></b-form-select>
                <label class="mr-sm-2" for="breakdowns-time-period">Time Period:</label>
                <b-form-select id="breakdowns-time-period" class="mb-2 mr-sm-2 mb-sm-0" v-model="timePeriod" :options="filterOptions.timePeriod" @change="requests"></b-form-select>
            </b-form>
            <date-range-picker v-model="dateRange" opens="left" @update="requests" class="mr-2">
                <template v-slot:input="picker" style="min-width: 350px;">
                    <span>{{ picker.startDate | date('MMM dd, yyyy') }} - {{ picker.endDate | date('MMM dd, yyyy') }}</span>
                    <i class="mdi mdi-calendar-blank" style="padding-left: 7px;"></i>
                </template>
            </date-range-picker>
        </div>
        <div v-if="chartOnly">
            <chart :key="chartOptions.Update" @getSVG="getSVG" v-if="chartOptions.series.length > 0" :chartOptions="chartOptions" :chartID="chartID"></chart> 
        </div>
        <div v-else>
            <highcharts :options="chartOptions" :style="`height: ${height};`" v-if="showChart"></highcharts>
            <div class="col-12 d-flex align-items-center justify-content-end mb-2" v-if="!latest && showTable && showChart">
                <b-button variant="light" class="mr-auto" @click="onExport" >Export</b-button>
                <b-form inline @submit.prevent>
                    <label class="mr-sm-2" for="breakdowns-type-filter">Type:</label>
                    <b-form-select id="breakdowns-type-filter" class="mb-2 mr-sm-2 mb-sm-0" v-model="type" :options="filterOptions.type" @change="requests"></b-form-select>
                    <label class="mr-sm-2" for="breakdowns-notation-filter">Notation:</label>
                    <b-form-select id="breakdowns-notation-filter" class="mb-2 mr-sm-2 mb-sm-0" v-model="notation" :options="filterOptions.notation" @change="onNotationChange"></b-form-select>
                    <label class="mr-sm-2" for="breakdowns-time-period">Time Period:</label>
                    <b-form-select id="breakdowns-time-period" class="mb-2 mr-sm-2 mb-sm-0" v-model="timePeriod" :options="filterOptions.timePeriod" @change="requests"></b-form-select>
                </b-form>
                <date-range-picker v-model="dateRange" opens="left" @update="requests" class="mr-2" :ranges="dateRanges">
                    <template v-slot:input="picker" style="min-width: 350px;">
                        <span>{{ picker.startDate | date('MMM dd, yyyy') }} - {{ picker.endDate | date('MMM dd, yyyy') }}</span>
                        <i class="mdi mdi-calendar-blank" style="padding-left: 7px;"></i>
                    </template>
                </date-range-picker>
            </div>
        </div>
        <template v-if="showTable">
                <b-table id="breakdowns-table" :items="tableOptions.items" :fields="tableOptions.columns" responsive class="text-center"
                    show-empty :per-page="50" :current-page="tableOptions.currentPage">
                    <template slot="empty">
                        No results found
                    </template>
                    <template v-slot:cell()="data">
                        <span v-if="parseFloat(data.value) === 0">-</span>
                        <span v-else-if="parseFloat(data.value) > 0" style="color: green;">
                            {{data.value}}
                        </span>
                        <span v-else-if="parseFloat(data.value) < 0" style="color: red;">
                            {{data.value}}
                        </span>
                        <span v-else>
                            {{data.value}}
                        </span>
                    </template>
                </b-table>
                <div class="row mt-3">
                    <div class="col-12 d-flex align-items-center justify-content-start">
                        <b-pagination v-model="tableOptions.currentPage" :total-rows="tableOptions.items.length" :per-page="50" aria-controls="breakdowns-table"></b-pagination>
                    </div>
                </div>
        </template>
    </div>
</template>

<script>
import { compareAsc, subBusinessDays, isToday, startOfMonth, endOfMonth, format, startOfWeek, endOfWeek, addMonths, addDays, startOfQuarter, endOfQuarter, addQuarters, startOfYear, endOfYear, addYears, formatISO9075 } from 'date-fns';
import { numberFormat, objectEach } from 'highcharts';
import DateRangePicker from 'vue-daterange-picker-light';
import 'vue-daterange-picker-light/dist/vue-daterange-picker-light.css';
import { getColor } from '@/lib/ColorLookup';
import { exportCsv } from '@/lib/Exporting';
import { map, clone, isEqual } from 'lodash';
import chart from '@/widgets/highchart'


//exporting
import Highcharts from "highcharts";
import VueHighcharts from "highcharts-vue";
import exporting from "highcharts/modules/exporting";
import offlineExporting from "highcharts/modules/offline-exporting";

import {Chart} from 'highcharts-vue';

exporting(Highcharts);
offlineExporting(Highcharts);

export default {
    name: 'breakdowns',
    data() {
        let date = new Date();
        let that = this;
        let exportingOptions = {
            filename: "Breakdowns",
            sourceWidth: "1500",
            sourceHeight: "500",
            chartOptions: { // specific options for the exported image
                plotOptions: {
                    series: {
                        dataLabels: {
                            enabled: false,
                        }
                    },
                },
                chart: {
                        backgroundColor:"white",
                        
                },
                title: {text:"Breakdowns"},
                
            },
           
            buttons:{
                contextButton:{
                    menuItems:["viewFullscreen", "printChart", "separator", "downloadPDF","downloadSVG","downloadPNG"]
                }
            },
            fallbackToExportServer: false,
        };
        let chartOptions = {};
        if(this.latest) {
            chartOptions = {
                exporting: exportingOptions,
                chart: {
                    type: 'bar',
                },
                title: {
                    text: ''
                },
                xAxis: {
                    title: {
                        text: null
                    },
                    labels: {
                        enabled: false
                    }
                },
                yAxis: {
                    min: -2,
                    max: 2,
                    title: {
                        text: 'Return'
                    },
                    labels: {
                        overflow: 'justify',
                        formatter: function() {
                            return this.value + '%';
                        }
                    }
                },
                tooltip: {
                    valueSuffix: '%',
                    formatter: function() {
                        return '<b>' + this.series.name + '</b>: ' + numberFormat(this.y, 3) + '%';
                    }
                },
                plotOptions: {//I think this creates the points of the chart/ 
                    bar: {
                        dataLabels: {
                            enabled: true,
                            formatter: function () {
                                return '<b>' + numberFormat(this.y, 3) + '%</b>';
                            }
                        }
                    }
                },
                credits: {
                    enabled: false
                },
                series: [],
                Update:false
            };
        } else {
            chartOptions = {
                exporting: exportingOptions,
                chart: {
                    type: 'line',
                    zoomType: 'x'
                },
                title: {
                    text: ''
                },
                xAxis: {
                    type: 'datetime'
                },
                yAxis: {
                    title: {
                        text: 'Return'
                    },
                    labels: {
                        overflow: 'justify',
                        formatter: function() {
                            return this.value + '%';
                        }
                    }
                },
                tooltip: {
                    valueSuffix: '%',
                    valueDecimals: 3
                },
                legend: {
                    enabled: true
                },
                credits: {
                    enabled: false
                },
                plotOptions: {//this is the point option
                    line: {
                        marker: {
                            symbol: 'circle'
                        }
                    }
                },
                rangeSelector: {
                    enabled: true,
                    verticalAlign: 'top',
                    x: 0,
                    y: 0
                },
                series: [],
                Update:false
            };
        }

        return {
            chartDateRange: {
                startDate: new Date(2015, 1, 1),
                endDate: endOfMonth(date)
            },
            dateRange: {
                startDate: startOfWeek(date),
                endDate: endOfWeek(date)
            },
            type: 'Market',
            notation: 'Percentage',
            timePeriod: 'Daily',
            filterOptions: {
                type: [
                    {
                        value: 'Market',
                        text: 'Market'
                    },
                    {
                        value: 'Bucket',
                        text: 'Bucket'
                    },
                    {
                        value: 'Factor',
                        text: 'Factor'
                    }
                ],
                notation: [
                    {
                        value: 'Percentage',
                        text: 'Percentage'
                    },
                    {
                        value: 'Cash',
                        text: 'Cash'
                    },
                    {
                        value: 'Tick',
                        text: 'Tick'
                    }
                ],
                timePeriod: [
                    {
                        value: 'Monthly',
                        text: 'Monthly'
                    },
                    {
                        value: 'Weekly',
                        text: 'Weekly'
                    },
                    {
                        value: 'Daily',
                        text: 'Daily'
                    }
                ]
            },
            chartOptions: chartOptions,
            tableOptions: {
                columns: [],
                items: [],
                currentPage: 1
            },
            dateRanges: {
                'This Week': [startOfWeek(date), endOfWeek(date)],
                'This Month': [startOfMonth(date), endOfMonth(date)],
                'Last Month': [startOfMonth(addMonths(date, -1)), endOfMonth(addMonths(date, -1))],
                'This Quarter': [startOfQuarter(date), endOfQuarter(date)],
                'Last Quarter': [startOfQuarter(addQuarters(date, -1)), endOfQuarter(addQuarters(date, -1))],
                'This Year': [startOfYear(date), endOfYear(date)],
                'Last Year': [startOfYear(addYears(date, -1)), endOfYear(addYears(date, -1))],
                'All Time': [new Date('2015-12-01'), endOfYear(date)]
            }
        };
    },
    props: {
        account: {
            type: String,
            default: ''
        },
        showTable: {
            type: Boolean,
            default: true
        },
        showChart: {
            type: Boolean,
            default: true
        },
        height: {
            type: String,
            default: '400px'
        },
        latest: {
            type: Boolean,
            default: false
        },
        dateEnd: {
            type: Date,
            default: null
        },
        dateStart: {
            type: Date,
            default: null
        },
        chartOnly:{
            type:Boolean,
            default: false
        },
        chartID:{
            type:String,
            default:"0"
        },
        ID:{
            type:Number,
            default:null
        },
        filterType:{
            type:Number,
            default:null
        }
    },
    components: {
        DateRangePicker,
        chart
    },
    mounted() {
        this.requests();
    },
    beforeDestroy() {
        this.$ws.off('reconnected', this.requests);
        this.$ws.off('breakdowns', this.onBreakdowns);
        this.$ws.off('breakdowns', this.onBreakdownsChart);
    },
    methods: {
        requests() {
            if(!this.hasSetupListeners) {
                this.$ws.on('reconnected', this.requests);
                this.$ws.on('breakdowns', this.onBreakdowns);
                this.$ws.on('breakdowns', this.onBreakdownsChart);
                this.hasSetupListeners = true;
            }

            let account = this.account;
            if(!account) {
                account = this.$store.state.activeAccount;
            }

            if(this.filterType == -1){
                this.type = "FactorSplit";
            }else{
                this.type = this.filterType == null ? this.type : this.filterOptions.type[this.filterType].value;
            }
            

            if( (this.ID != null && this.showChart == true) || this.ID == null){
                this.$ws.send({
                    sessionID: 'NotImplemented',
                    accountName: account,
                    request: 'Breakdowns',
                    args: {
                        filterType: this.type,
                        notationType: this.notation,
                        timePeriod: this.timePeriod,
                        startDate: this.dateStart == null ? format(this.chartDateRange.startDate, 'yyyy-MM-dd') : format(this.dateStart, 'yyyy-MM-dd'),
                        endDate: this.dateEnd == null ? format(this.chartDateRange.endDate, 'yyyy-MM-dd') : format(this.dateEnd, 'yyyy-MM-dd'),
                        today: this.latest,
                        chart: true
                    }
                });
            }
            

            if(!this.latest){
                this.$ws.send({
                    sessionID: 'NotImplemented',
                    accountName: account,
                    request: 'Breakdowns',
                    args: {
                        filterType: this.type,
                        notationType: this.notation,
                        timePeriod: this.timePeriod,
                        startDate: this.dateStart == null ? format(this.dateRange.startDate, 'yyyy-MM-dd') : format(this.dateStart, 'yyyy-MM-dd'),
                        endDate: this.dateEnd == null ? format(this.dateRange.endDate, 'yyyy-MM-dd') : format(this.dateEnd, 'yyyy-MM-dd'),
                        today: this.latest,
                        id: this.chartID
                    }
                });
            }
        },
        onBreakdowns(event) {
            if(event.request.args.hasOwnProperty('chart') 
            || event.request.args.filterType != this.type
            || event.request.args.id != 0) {
                return;
            }

            if(event.request.args.filterType == "FactorSplit"){

                if(Object.keys(event.response).length > 2){
                    for(let obj of Object.keys(event.response)){
                        obj = event.response[obj]
                        let markets = Object.keys(obj.totals)
                        let date = new Date(event.request.args.startDate)
                        let startWeek = addDays(date, (-date.getDay())+1)
                        let newItems = []
                        for(let i=0;i < 5; i++){
                            let date = format(addDays(startWeek, i), "yyyy-MM-dd") + "T00:00:00";
                            let keys = Object.keys(obj.data)
                            if(!keys.includes(date)){
                                newItems[date] = {}
                            }
                            for(let z in obj.data){
                                for(let market of markets){
                                    let value = 0
                                    if(keys.includes(date) && obj.data[date].hasOwnProperty(market)){
                                        value = obj.data[date][market]
                                    }
                                    if(!newItems.hasOwnProperty(date)){
                                        newItems[date] = {}
                                    }
                                    newItems[date][market] = value
                                }
                                
                                
                            }
                        }
                        obj.data = newItems
                    }
                }
                
                this.$emit("getBreakdownsFactorSplit", event.response);
                return;
            }

            let decimals = 3;

            if(this.notation === 'Tick') {
                decimals = 0;
            }

            let columns = [];
            let items = [];
            //set first column entry
            columns.push({
                key: "market",
                label: "Markets",
                sortable: true,
            });
            //set columns as dates
            let dates = Object.keys(event.response.data);
            dates.forEach((date)=>{
                columns.push({
                    key: date,
                    label: format(new Date(date), "MMM dd, yyyy"),
                    sortable: true
                });
            })
            //add total of dates column at end
            columns.push({
                key:"total",
                label:"Total",
                sortable:true,
                formatter:function(value){return isNaN(Number(value)) ? value : Number(value).toFixed(decimals)}
            })

            let keys = Object.keys(event.response.data);

            //change Stat to Trend 2.0
            if(Object.keys(event.response.totals).includes("Stat")){
                event.response.totals["Trend 2.0"] = event.response.totals["Stat"]
                delete event.response.totals["Stat"]
            }
            if( keys.length > 0 && Object.keys(event.response.data[keys[0]]).includes("Stat")){
                for(let date of Object.keys(event.response.data)){
                    event.response.data[date]["Trend 2.0"] = event.response.data[date]["Stat"]
                    delete event.response.data[date]["Stat"]
                }
            }

            //fill in market column for every row
            let markets = Object.keys(event.response.totals);

            markets.forEach((market)=>{
                items.push({market:market})
            })

            
            let len = keys.length

            //set rows
           for(let i = 0; i<len;i++){
               for(let market in event.response.data[keys[i]]){
                   //get item from this market
                   let item = items.find((item)=>{return item.market == market})
                   let value = event.response.data[keys[i]][market]
                   item[keys[i]] = (value * 100 / 100).toFixed(decimals);
               }
           }

            //set totals column data
           let columnTotal = 0;
           for(let market in event.response.totals){
               let item = items.find((item)=>{return item.market == market})
               let value = event.response.totals[market]
               item['total'] = value;
               columnTotal += value;
           }

           //set total row
           let rowTotal = {market:'Total'};
           let accumalativeSum = 0;
           for(let date in event.response.data){
               let sum = 0;
               for(let market in event.response.data[date]){
                   let value = event.response.data[date][market]
                   sum += Number(value)
               }
               accumalativeSum += Number(sum);
               rowTotal[date] = Number(sum).toFixed(decimals);
           }
           //set total column
           rowTotal['total'] = accumalativeSum;
           items.push(rowTotal)

            this.tableOptions.columns = columns;
            this.tableOptions.items = items;
            
            if(this.ID != null){
                //fill empty days of week
                let date = new Date(dates[0])
                let startWeek = addDays(date, (-date.getDay())+1)
                let newItems = []
                for(let i=0;i < 5; i++){
                    let date = format(addDays(startWeek, i), "yyyy-MM-dd") + "T00:00:00";
                    for(let z in items){
                        if(newItems[z] == undefined){
                            newItems[z] = {market:items[z].market}
                        }
                        let value = "0"
                        if(Object.keys(items[z]).includes(date)){
                            value = items[z][date]
                        }
                        newItems[z][date] = value
                    }
                }
                newItems.map((x,i)=> {
                    x.total = items[i].total
                    return x
                })
                this.$emit("getData", newItems, this.ID)
            }
            
        },
        onBreakdownsChart(event) {
            if(!event.request.args.hasOwnProperty('chart') 
            || event.request.args.filterType != this.type
            ){
                return;
            }

            let response = [];

            if(this.filterType !== -1) {
                response = event.response;
            } else if(this.ID === 20) {
                response = event.response.Energy;
            } else if(this.ID === 19) {
                response = event.response.Agriculture;
            } else if(this.ID === 21) {
                response = event.response.Equity;
            } else if(this.ID === 22) {
                response = event.response.FX;
            } else if(this.ID === 23) {
                response = event.response.Metals;
            }

            if(this.latest) {
                let dates = Object.keys(response.data).map(function(value) {
                    return new Date(value);
                }).sort(compareAsc);

                if(dates.length > 0) {
                    let latestDate = dates[dates.length - 1];

                    // if(!isToday(latestDate)) {
                    //     console.log(latestDate);
                    //     return;
                    // }

                    let data = response.data[`${format(latestDate, 'yyyy-MM-dd', new Date())}T00:00:00`];

                    let min = -2;
                    let max = 2;

                    let total = 0;

                    let series = [];
                    for(let key in data) {
                        series.push({
                            name: key,
                            data: [data[key]],
                            color: getColor(key)
                        });

                        total += parseFloat(data[key]);

                        let ceilValue = Math.ceil(data[key]);
                        let floorValue = Math.floor(data[key]);

                        if(ceilValue > max) {
                            max = ceilValue;
                        } else if(ceilValue === max) {
                            max += 1;
                        }

                        if(floorValue < min) {
                            min = floorValue;
                        } else if(floorValue === min) {
                            min -= 1;
                        }
                    }

                    if(series.length > 0) {
                        series.push({
                            name: 'Total',
                            data: [total],
                            color: getColor('Total')
                        });
                    }

                    let ceilTotal = Math.ceil(total);
                    let floorTotal = Math.floor(total);

                    if(ceilTotal > max) {
                        max = ceilTotal;
                    } else if (ceilTotal === max) {
                        max += 1;
                    }

                    if(floorTotal < min) {
                        min = floorTotal;
                    } else if(floorTotal === min) {
                        min -= 1;
                    }

                    if(max !== this.chartOptions.yAxis.max) {
                        this.chartOptions.yAxis.max = max;
                    }

                    if(min !== this.chartOptions.yAxis.min) {
                        this.chartOptions.yAxis.min = min;
                    }

                    if(!isEqual(this.chartOptions.series, series)) {
                        this.chartOptions.series = series;
                    }
                }
            } else if(response != undefined) {
                let that = this;

                let series = [];
                //change Stat to Trend 2.0
                if(Object.keys(response.totals).includes("Stat")){
                    response.totals["Trend 2.0"] = response.totals["Stat"]
                    delete response.totals["Stat"]
                }
                let dataKeys = Object.keys(response.data)
                if( dataKeys.length > 0 && Object.keys(response.data[dataKeys[0]]).includes("Stat")){
                    for(let date of Object.keys(response.data)){
                        response.data[date]["Trend 2.0"] = response.data[date]["Stat"]
                        delete response.data[date]["Stat"]
                    }
                }
                let keys = Object.keys(response.totals);

                for(let key of keys) {
                    if(this.ID === 22 && (key != 'EU' && key != 'JP')) {
                        continue;
                    }

                    let label = key;
                    if(label === 'HFT') {
                        label = 'HFTVol';
                    }

                    let singleSeries = {
                        name: label,
                        data: [],
                        color: getColor(key)
                    };

                    let cumulative = 0;
                    let first = true;
                    for(let date in response.data) {
                        let parsedDate = new Date(date);

                        if(first) {
                            let firstDate = subBusinessDays(parsedDate, 1);
                            singleSeries.data.push([Date.UTC(firstDate.getFullYear(), firstDate.getMonth(), firstDate.getDate()), 0]);
                            first = false;
                        }

                        cumulative += response.data[date][key];

                        singleSeries.data.push([Date.UTC(parsedDate.getFullYear(), parsedDate.getMonth(), parsedDate.getDate()), cumulative]);
                    }

                    series.push(singleSeries);
                }

                let totalSeries = {
                    name: 'Total',
                    data: [],
                    color: getColor('Total')
                };

                let cumulativeTotal = 0;
                let first = true;
                for(let date in response.data) {
                    let parsedDate = new Date(date);

                    if(first) {
                        let firstDate = subBusinessDays(parsedDate, 1);
                        totalSeries.data.push([Date.UTC(firstDate.getFullYear(), firstDate.getMonth(), firstDate.getDate()), 0]);
                        first = false;
                    }

                    let total = 0;
                    for(let key in response.data[date]) {
                        cumulativeTotal += response.data[date][key];
                    }
                    
                    totalSeries.data.push([Date.UTC(parsedDate.getFullYear(), parsedDate.getMonth(), parsedDate.getDate()), cumulativeTotal]);
                }

                series.push(totalSeries);

                this.chartOptions.series = series;

                this.chartOptions.Update = !this.chartOptions.Update
            }
        },
        onNotationChange(event) {
            if(this.notation === 'Percentage') {
                this.chartOptions.tooltip.valueSuffix = '%';
                this.chartOptions.tooltip.valuePrefix = '';
                this.chartOptions.tooltip.valueDecimals = 3;
                this.chartOptions.yAxis.labels.formatter = function() {
                    return this.value + '%';
                }
            } else {
                this.chartOptions.tooltip.valueSuffix = '';

                let activeCurrency = this.$store.state.activeCurrency;
                if(activeCurrency === 'GBP') {
                    this.chartOptions.tooltip.valuePrefix = '£';
                } else {
                    this.chartOptions.tooltip.valuePrefix = '$';
                }

                this.chartOptions.tooltip.valueDecimals = 0;

                let that = this;
                this.chartOptions.yAxis.labels.formatter = function() {
                    return that.$options.filters.currency(that.$options.filters.number(this.value, 0));
                }
            }

            this.requests();
        },
        getSum(field) {
            let sum = 0;
            for(let item of this.tableOptions.items) {
                sum += parseFloat(item[field]);
            }

            return this.$options.filters.number(sum);
        },
        getSVG(svg){
            if(this.ID != null){
                this.$emit("getSVG", svg , this.ID);
            }
        },
        onExport() {
            let headers = {};

            for(let column of this.tableOptions.columns) {
                if(column.key.toLowerCase() == "total" || column.key.toLowerCase() == "market"){
                    headers[column.key] = column.label
                }else{
                    headers[column.key] = format(new Date (column.key), 'yyyy-MM-dd hh:mm:ss');
                }
                
            }
            let items = map(this.tableOptions.items, clone)

            exportCsv(headers, items, 'Breakdowns');
        }
    }
}
</script>
